body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100% !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



html {
  overflow: -moz-scrollbars-none; 
  -ms-overflow-style: none;
}
html::-webkit-scrollbar { /*webkit内核*/
  display: none;
}
/* body{width: 100vw;height: 100vh;} */
#root{width: 100%;min-height: 100vh;}

.ant-carousel .slick-dots li.slick-active button{background-color: #fc0;}
.slide-container{height: 100vh !important;}
.slide-page{overflow: hidden;}
.flexCenter{display: flex;align-items: center;}


.btnn{width: 140px;height: 40px;border-radius: 4px;background-color: #FFCC00;display: flex;align-items: center;justify-content: center;color: #000;position: absolute;left: 50%;margin-left: -70px;top: 75%;cursor: pointer;}
.btnn::after{display: block;content: '';width: 20px;height: 20px;background-image: url('./imgs/play.png');background-size: 100% 100%;margin-left: 8px;}

.pubh1{font-size: 30px;color: #fff;display: flex;align-items: center;justify-content: center;font-weight: bold;margin-top: 40px;}
.pubh1::before,.pubh1::after{display: block;content: '';width: 90px;height: 1px;background-color: #fff;}
.pubh1::before{margin-right: 36px;}
.pubh1::after{margin-left: 36px;}
.pubp1{color: #999;text-align: center;margin: 10px 0 36px;}
.pubp2{font-size: 16px;color: #999;text-align: center;}
.page3 .box{width: 80%;margin: 5% auto 0;display: flex;align-items: flex-start;}


.navbar{position: fixed;left: 0;right: 0;z-index: 999999;display: flex;align-items: center;padding: 0 15px;}
.navbar .logo{display: block;width: 240px;margin-right: auto;}
.navbar .flex{display: flex;align-items: center;}
.navbar .flex .item{margin: 0 15px;padding: 0 15px;cursor: pointer;font-size: 16px;color: #999;}
.navbar .flex .item.active{color: #fff;}

.cbtn{display: block;width: 48px;position: fixed;right: 2.5vw;cursor: pointer;z-index: 9999999;}
.cbtn.prev{bottom: 144px;}
.cbtn.next{bottom: 80px;}
.tipss{position: fixed;left: 2.5vw;bottom: 80px;z-index: 99999;}
.tipss p{color: #fff;font-size: 12px;width: 12px;margin: 0 auto 10px;}
.tipss img{display: block;width: 24px;}

.page1 .box{position: relative;}
.page1 .box .pic{display: block;width: 100vw;height: 100vh;}
.page1 .box .p1{font-size: 60px;color: #fff;position: absolute;left: 8vw;top: 15.6vw;opacity: 0;}
.page1 .box .p2{font-size: 60px;color: #999;position: absolute;left: 8vw;top: 20vw;opacity: 0;}
.page1 .box .p3{font-size: 16px;color: #999;position: absolute;left: 8vw;top: 26vw;opacity: 0;}
.page1 .active .box.focus .p1{
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  animation-delay: .3s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}
.page1 .active .box.focus .p2{
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  animation-delay: .7s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}
.page1 .active .box.focus .p3{
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  animation-delay: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

.page2 .flwp{display: flex;flex-wrap: wrap;overflow: hidden;}
.page2 .flwp>div{width: 33.33%;height: 50vh;position: relative;cursor: pointer;overflow: hidden;transform: translate3d(0,-100%,0);}
.page2 .flwp>div:nth-child(4),.page2 .flwp>div:nth-child(5),.page2 .flwp>div:nth-child(6){transform: translate3d(0,100%,0);}
.page2 .active.flwp>div{-webkit-animation-duration: .2s;
  animation-duration: .2s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.page2 .active.flwp>div:nth-child(1){
  -webkit-animation-name: slideToBottom;
  animation-name: slideToBottom;
}
.page2 .active.flwp>div:nth-child(2){
  animation-delay: .3s;
  -webkit-animation-name: slideToBottom;
  animation-name: slideToBottom;
}
.page2 .active.flwp>div:nth-child(3){
  animation-delay: .6s;
  -webkit-animation-name: slideToBottom;
  animation-name: slideToBottom;
}
.page2 .active.flwp>div:nth-child(4){
  animation-delay: .6s;
  -webkit-animation-name: slideToTop;
  animation-name: slideToTop;
}
.page2 .active.flwp>div:nth-child(5){
  animation-delay: .3s;
  -webkit-animation-name: slideToTop;
  animation-name: slideToTop;
}
.page2 .active.flwp>div:nth-child(6){
  -webkit-animation-name: slideToTop;
  animation-name: slideToTop;
}
.page2 .flwp .pic{display: block;width: 100%;height: 100%;}
.page2 .flwp .mask{position: absolute;left: 0;top: 0;right: 0;bottom: 0;}
.page2 .flwp .mask .top,.page2 .flwp .mask .bot{width: 100%;height: 50%;position: absolute;left: 0;right: 0;background-color: rgba(0, 0, 0, .8);transition: .8s;}
.page2 .flwp .mask .top{top: 0;overflow: hidden;}
.page2 .flwp .mask .bot{bottom: 0;}
.page2 .flwp .icon{display: block;width: 80px;position: absolute;left: 50%;margin-left: -40px;top: 30%;transition: .5s;transition-delay: .2s;}
.page2 .flwp h1{font-size: 32px;color: #fff;position: absolute;left: 0;right: 0;text-align: center;top: 54%;transition: .5s;transition-delay: .2s;}
.page2 .flwp .line{width: 24px;height: 2px;background-color: #FFCC00;position: absolute;left: 50%;margin-left: -24px;top: 70%;transition: .4s;}
.page2 .flwp .line1{width: 24px;height: 2px;background-color: #FFCC00;position: absolute;right: 50%;margin-right: -24px;top: 70%;transition: .4s;}
.page2 .flwp>div:hover .top{height: 0;}
.page2 .flwp>div:hover .bot{height: 0;}
.page2 .flwp>div:hover .icon{opacity: 0;-webkit-transform: translate3d(0,-150%,0);transform: translate3d(0,-150%,0);}
.page2 .flwp>div:hover .mask h1{opacity: 0;-webkit-transform: translate3d(0,150%,0);transform: translate3d(0,150%,0);}
.page2 .flwp>div:hover .line{width: 0;}
.page2 .flwp>div:hover .line1{width: 0;}
.page2 .open{position: absolute;left: 0;top: 0;right: 0;bottom: 0;background-color: rgba(0, 0, 0, .3);transition: .5s;opacity: 0;}
.page2 .flwp>div:hover .open{opacity: 1;transition-delay: .4s;}
.page2 .open h1{top: 30%;}
.page2 .open .p1{font-size: 14px;color: #fff;position: absolute;left: 15%;right: 15%;top: 60%;text-align: center;line-height: 20px;}
.page2 .open .line2{width: 50%;height: 2px;background-color: rgba(255, 255, 255, .2);position: absolute;left: 25%;top: 48%;}
.page2 .open .line2::before{display: block;content: '';width: 20%;height: 2px;background-color: #FFCC00;margin: 0 auto;}

.page3,.page4,.page5,.page6{background-image: url('./imgs/bg3.png');background-size: 100% 100%;padding-top: 90px;}
.page3 .box .item{flex: 1;margin-right: 27px;border-radius: 8px;overflow: hidden;background-color: #fff;position: relative;top: 0;cursor: pointer;transition: .5s;opacity: 0;}
.page3 .box .item:nth-child(4n){margin-right: 0;}
.page3 .box .item .img{display: block;width: 100%;}
.page3 .item .mask{position: absolute;left: 0;top: 0;right: 0;bottom: 123px;background-color: rgba(0, 0, 0, .04);display: flex;align-items: center;justify-content: center;transition: .8s;border-radius: 8px 8px 0 0;}
.page3 .item .mask .search{display: block;width: 72px;opacity: 0;transition: .8s;}
.page3 .item:hover{top: -15px}
.page3 .item:hover .mask{background-color: rgba(0, 0, 0, .3);}
.page3 .item:hover .mask .search{opacity: 1;}
.page3 .btnn{top: 85%;}
.page3 .item .wrap{padding: 15px;}
.page3 .item .flex{display: flex;align-items: center;}
.page3 .item .flex h1{flex: 1;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;font-weight: bold;}
.page3 .item .flex .jt{display: block;width: 24px;}
.page3 .item .desc{color: #999;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin: 15px 0 20px;}
.page3 .item .type{width: auto;width: fit-content;padding: 0 6px;line-height: 20px;border-radius: 3px;background-color: #333;color: #FFCC00;font-size: 12px;}
.page3 .pubh1,.page3 .pubp1,.page3 .pubp2,.page3 .btnn,.page5 .pubh1,.page5 .pubp1,.page5 .pubp2{opacity: 0;}
.page3 .active .pubh1,.page5 .active .pubh1{
  -webkit-animation-duration: .6s;
  animation-duration: .6s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important
}
.page3 .active .pubp1,.page5 .active .pubp1{
  -webkit-animation-duration: .6s;
  animation-duration: .6s;
  animation-delay: .3s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important
}
.page3 .active .pubp2,.page5 .active .pubp2{
  -webkit-animation-duration: .6;
  animation-duration: .6s;
  animation-delay: .6s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important
}
.page3 .active .box .item{
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  animation-delay: .9s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
.page3 .active .box .item:nth-child(2){
  animation-delay: 1.1s;
}
.page3 .active .box .item:nth-child(3){
  animation-delay: 1.3s;
}
.page3 .active .box .item:nth-child(4){
  animation-delay: 1.5s;
}
.page3 .active .btnn{
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  animation-delay: 1.8s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important
}





.page4{background-image: url('./imgs/bg4.png');}
.page4 .box{width: 72%;margin: 0 auto;position: relative;}
.page4 .box .list{margin-bottom: 24px;}
.page4 .box .list .pic{display: block;width: 200px;}
.page4 .ant-carousel .slick-dots-bottom{bottom: -30px;}
.page4 .pubh1,.page4 .pubp1,.page4 .pubp2,.page4 .ant-carousel,.page5 .ant-carousel,.page6 .pubh1,.page6 .pubp1,.page6 .pubp2{opacity: 0;}
.page4 .active .pubh1,.page6 .active .pubh1{
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
	-webkit-animation-name:zoomIn;
	animation-name:zoomIn
}
.page4 .active .pubp1,.page6 .active .pubp1{
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  animation-delay: .6s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
	-webkit-animation-name:zoomIn;
	animation-name:zoomIn
}
.page4 .active .pubp2,.page6 .active .pubp2{
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  animation-delay: 1.2s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
	-webkit-animation-name:zoomIn;
	animation-name:zoomIn
}
.page4 .active .ant-carousel{
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  animation-delay: 1.8s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
	-webkit-animation-name:zoomIn;
	animation-name:zoomIn
}
.page4 .active .jtt{
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  animation-delay: 2s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
	-webkit-animation-name:zoomIn;
	animation-name:zoomIn
}



.page5{background-image: url('./imgs/bg5.png');}
.page5 .box{width: 67%;margin: 0 auto;position: relative;}
.page5 .box .list{margin-bottom: 48px;position: relative;overflow: hidden;}
.page5 .box .list .pic{display: block;width: 260px;height: 124px;border-radius: 4px;}
.page5 .box .list .mask{position: absolute;width: 264px;left: 0;top: 0;bottom: 0;background-color: #444;transform: translate(-100%,0);transition: .4s;}
.page5 .box .list .mask .sj{display: block;width: 12px;margin: 8px 0 20px 8px;}
.page5 .box .list .mask p{font-size: 18px;color: #fff;text-align: center;}
.page5 .box .list .mask span{display: block;color: #999;text-align: center;margin-top: 10px;}
.page5 .box .list .mask .pp{display: block;width: 58px;position: absolute;right: 0;bottom: 0;}
.page5 .box .list:hover .mask{
  transform: translate(0,0);
}

.page5 .ant-carousel .slick-dots-bottom{bottom: -40px;}
.page5 .active .ant-carousel{
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  animation-delay: .9s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important
}
.page5 .box .jtt,.page4 .box .jtt{display: block;width: 48px;position: absolute;top: 50%;margin-top: -48px;opacity: 0;}
.page5 .box .prev,.page4 .box .prev{transform: rotate(90deg);left: -10%;}
.page5 .box .next,.page4 .box .next{transform: rotate(-90deg);right: -10%;}
.page5 .active .jtt{
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  animation-delay: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
	-webkit-animation-name:zoomIn;
	animation-name:zoomIn
}

.page6{background-image: url('./imgs/bg6.png');}
.page6 .flex{display: flex;justify-content: center;align-items: center;margin-top: 100px;}
.page6 .flex>div{margin-right: 44px;}
.page6 .flex>div:nth-child(3n){margin-right: 0;}
.page6 .flex>div img{display: block;width: 160px;border-radius: 4px;}
.page6 .flex>div p{color: #fff;text-align: center;margin-top: 10px;}
.page6 .flex>img{display: block;width: 24px;margin-right: 10px;}
.page6 .flex>p{color: #fff;margin-right: 75px;}
.page6 .line{width: 200px;height: 1px;background-color: #FFCC00;margin: 40px auto;}
.page6 .tu,.page6 .addr,.page6 .line,.page6 .banq{opacity: 0;}
.page6 .active .tu{
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
.page6 .active .tu1{animation-delay: 1.6s;}
.page6 .active .tu2{animation-delay: 2s;}
.page6 .active .tu3{animation-delay: 2.4s;}
.page6 .active .addr{
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  animation-delay: 3s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}
.page6 .active .line{
  -webkit-animation-duration: .6s;
  animation-duration: .6s;
  animation-delay: 3.6s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
	-webkit-animation-name:zoomIn;
	animation-name:zoomIn
}
.page6 .active .banq{
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  animation-delay: 4s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

/* 案例中心 */
.fixed{position: fixed;left: 0;top: 0;right: 0;z-index: 9999;}
.head{width: 100%;background-color: #fff;z-index: 999999;border-bottom: 1px solid #EFEFEF;display: flex;align-items: center;padding: 0 2.5vw 0 2vw;}
.head .logo{display: block;width: 200px;}
.head h1{font-size: 20px;font-weight: bold;margin-left: 2px;}
.head .back{width: 80px;height: 40px;border-radius: 20px;border: 1px solid #EFEFEF;display: flex;align-items: center;justify-content: center;cursor: pointer;margin-left: auto;}
.head .back::after{display: block;content: '';width: 24px;height: 24px;background-image: url('./imgs/jt.png');background-size: 100% 100%;}
.casetype{display: flex;flex-wrap: wrap;padding: 32px 0 24px;justify-content: center;background-color: #f8f8f8;}
.casetype p{font-size: 16px;padding: 0 18px;border-radius: 8px;background-color: #DFDFDF;color: #666;line-height: 40px;margin-right: 16px;cursor: pointer;}
.casetype p:last-child{margin-right: 0;}
.casebox{padding: 187px 2.5vw 0;height: 100vh;overflow: scroll;}
.casetype p.active,.casetype p:hover{background-color: #333;color: #FFCC00;overflow: hidden;}
.casebox::-webkit-scrollbar{display: none;}
.caselist{display: flex;flex-wrap: wrap;max-height: 72vh;overflow: auto;}
.caselist::-webkit-scrollbar{display: none;}
.caselist .row{width: 18vw;margin-right: 1.25vw;margin-top: 1.25vw;border-radius: 8px;overflow: hidden;background-color: #fff;position: relative;cursor: pointer;transition: .5s;}
.caselist .row:nth-child(5n){margin-right: 0;}
.caselist .row .img{display: block;width: 100%;}
.caselist .row .mask{position: absolute;left: 0;top: 0;right: 0;bottom: 123px;background-color: rgba(0, 0, 0, .04);display: flex;align-items: center;justify-content: center;transition: .8s;border-radius: 8px 8px 0 0;}
.caselist .row .mask .search{display: block;width: 72px;opacity: 0;transition: .8s;}
.caselist .row:hover{transform: translate3d(0,-15px,0);}
.caselist .row:hover .mask{background-color: rgba(0, 0, 0, .3);}
.caselist .row:hover .mask .search{opacity: 1;}
.page3 .btnn{top: 85%;}
.caselist .row .wrap{padding: 15px;}
.caselist .row .flex{display: flex;align-items: center;}
.caselist .row .flex h1{flex: 1;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;font-weight: bold;}
.caselist .row .flex .jt{display: block;width: 24px;}
.caselist .row .desc{color: #999;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin: 15px 0 20px;}

.loading-container{text-align: center;padding: 20px 0;background-color: #f8f8f8;}
.loading-container .ant-spin-dot-item{background-color: #FFCC00;}
.loading-container .ant-spin-text{color: #666;padding: 10px 0 0;}

.detailbox{width: fit-content;margin: 114px auto 30px;position: relative;}
.detailbox .box{width: 730px;background-color: #fff;padding-top: 48px;border-radius: 8px;margin-right: 24px;}
.detailbox .box h1{font-size: 30px;font-weight: bold;text-align: center;}
.detailbox .box .time{color: #666;padding: 24px 0 30px;border-bottom: 1px solid #EFEFEF;text-align: center;}
.detailbox .box .wrap{padding: 30px 24px;/*overflow: scroll;height: 100%;margin-top: 30px;*/}
.detailbox .box .wrap::-webkit-scrollbar{display: none;}
.detailbox .box .desc{padding-bottom: 20px;line-height: 24px;}
.detailbox .box .pic{display: block;width: 100%;}
.detailbox .caselist{width: 18vw;position: fixed;right: calc((100vw - 754px) / 2 - 18vw);top: 114px;}
.detailbox .caselist .row{width: 100%;margin-right: 0;}
.detailbox .caselist .row:hover{transform: none;}
.detailbox .caselist .row:first-child{margin-top: 0;}


@-webkit-keyframes slideInUp {
	0% {
    -webkit-transform:translate3d(0,100%,0);
    transform:translate3d(0,100%,0);
    visibility:visible
  }
  to {
    opacity: 1;
    -webkit-transform:translateZ(0);
    transform:translateZ(0)
  }
}
@keyframes slideInUp {
	0% {
    -webkit-transform:translate3d(0,100%,0);
    transform:translate3d(0,100%,0);
    visibility:visible
  }
  to {
    opacity: 1;
    -webkit-transform:translateZ(0);
    transform:translateZ(0)
  }
}
@-webkit-keyframes zoomIn {
	0% {
    opacity:0;
    -webkit-transform:scale3d(.3,.3,.3);
    transform:scale3d(.3,.3,.3)
  }
  50%,100% {
    opacity:1
  }
}
@keyframes zoomIn {
	0% {
    opacity:0;
    -webkit-transform:scale3d(.3,.3,.3);
    transform:scale3d(.3,.3,.3)
  }
  50%,100% {
    opacity:1
  }
}

@-webkit-keyframes flipInX {
  0% {
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
      -webkit-transform: perspective(400px) rotateX(90deg);
      transform: perspective(400px) rotateX(90deg)
  }

  40% {
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      -webkit-transform: perspective(400px) rotateX(-20deg);
      transform: perspective(400px) rotateX(-20deg)
  }

  60% {
      opacity: 1;
      -webkit-transform: perspective(400px) rotateX(10deg);
      transform: perspective(400px) rotateX(10deg)
  }

  80% {
      -webkit-transform: perspective(400px) rotateX(-5deg);
      transform: perspective(400px) rotateX(-5deg)
  }

  to {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
      opacity: 1;
  }
}

@keyframes flipInX {
  0% {
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
      -webkit-transform: perspective(400px) rotateX(90deg);
      transform: perspective(400px) rotateX(90deg)
  }

  40% {
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      -webkit-transform: perspective(400px) rotateX(-20deg);
      transform: perspective(400px) rotateX(-20deg)
  }

  60% {
      opacity: 1;
      -webkit-transform: perspective(400px) rotateX(10deg);
      transform: perspective(400px) rotateX(10deg)
  }

  80% {
      -webkit-transform: perspective(400px) rotateX(-5deg);
      transform: perspective(400px) rotateX(-5deg)
  }

  to {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
      opacity: 1;
  }
}

@-webkit-keyframes fadeInRight {
  0% {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0)
  }

  to {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0)
  }
}

@keyframes fadeInRight {
  0% {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0)
  }

  to {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0)
  }
}
@keyframes slideInRight{
  0%{
    transform: translate3d(100%,0,0);opacity: 0;
  }
  100%{
    transform: translate3d(0%,0,0);opacity: 1;
  }
}
@keyframes slideToBottom{
  0%{
    transform: translate3d(0,-100%,0);
  }
  100%{
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}
@keyframes slideToTop{
  0%{
    transform: translate3d(0,100%,0);
  }
  100%{
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}




@-webkit-keyframes fadeOutRight {
  0% {
      opacity: 1
  }

  to {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0)
  }
}

@keyframes fadeOutRight {
  0% {
      opacity: 1
  }

  to {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0)
  }
}


@-webkit-keyframes fadeOutTopRight {
  0% {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0)
  }

  to {
      opacity: 0;
      -webkit-transform: translate3d(100%, -100%, 0);
      transform: translate3d(100%, -100%, 0)
  }
}

@keyframes fadeOutTopRight {
  0% {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0)
  }

  to {
      opacity: 0;
      -webkit-transform: translate3d(100%, -100%, 0);
      transform: translate3d(100%, -100%, 0)
  }
}

